<template>
  <screen_width_cont>
    <!-- <div class="cont_300N"> -->
      <div class="cont_300_basico ancho_acotado">
      <div class="header_top">
        <headerCerrar @cancelar="cerrarModal" colorIcon="gris" />
      </div>

      <!-- título principal -->
      <div class="regEditAutentiTitle title">
        {{ t("app.registroEditAutentificacion.tit") }}
      </div>

      <!-- texto -->
      <div class="regEditAutentiText text_basico f_w_400">
        <span>{{ t("app.registroEditAutentificacion.hola") }}&nbsp;(</span>
        <span>{{ `${remitente.nombre1} ${remitente.nombre2}` }}),&nbsp;</span>
        <span>{{ t("app.registroEditAutentificacion.seguridad") }}&nbsp;</span>
        <span class="autenticaTextBold">{{ remitente.correo }}</span>
      </div>

      <!-- label código -->
      <div class="regEditAutentiCodigo">
        {{ t("app.registroEditAutentificacion.codigo") }}
      </div>

      <!-- input del código estilo mach -->
      <inputCodigoAutenticacion
        @revisarCodigoComp="revisarCodigoLocal"
        @tieneError="tieneErrorLocal"
        :liberar_campos="!procesando_codigo"
      />
      <div class="errorCodigoLabel">
        <span v-if="tieneError">{{
          t("app.registroEditAutentificacion.errorInvalido")
        }}</span>
      </div>

      <div class="regEditAutentiLoginResendedCod">
        <span v-show="codigoResended">{{
          t("app.registroEditAutentificacion.reSendCod")
        }}</span>
      </div>

      <!-- volver a generar código -->
      <div class="regEditAutentiResend text_basico f_w_500">
        <span @click="reSendCodigo" class="clickable">{{
          t("app.registroEditAutentificacion.regenerarCodigo")
        }}</span>
      </div>

      <div class="regEditAutentiEtapas">
        <pasosGiro paso="3" totales="3" />
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
import { alertController } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import inputCodigoAutenticacion from "../components/generales/inputCodigoAuteticacion.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroEditAutentificacion",
  components: {
    headerCerrar,
    inputCodigoAutenticacion,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      tieneError: false,
      title: "",
      subTitle: "",
      buttons: [],
      codigoResended: false,
      borrarDatos: false,
      procesando_codigo: false,
      enVista: false,
    };
  },
  computed: {
    ...mapState([
      "remitente",
      "respCodigoValidaMail",
      "procesoGeneral",
      "widthScreen",
      "respRemitenteModif",
      "plataforma",
      "url_base_web",
    ]),
  },
  methods: {
    ...mapActions([
      "revisarCodigoLogin",
      "remitenteUpdate",
      "verificar_codigo_logued",
      "re_send_cod_cambio_mail",
    ]),
    ...mapMutations([
      "limpiarRemitente",
      "updateBloquearNavegacion",
      "setshowSpinner",
      "dropRemitenteTemp",
    ]),

    cerrarModal() {
      if (this.plataforma == "web") {
        window.location.replace(
          `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
        );
      } else {
        if (this.procesoGeneral == "misDatos") {
          this.$router.push({ name: "misDatos", replace: true });
        } else if (this.procesoGeneral == "logIn") {
          this.$router.push({ name: "logIn", replace: true });
        }
      }
    },
    revisarCodigoLocal(codigo) {
      this.procesando_codigo = true;
      this.setshowSpinner(true);
      this.verificar_codigo_logued(codigo);
    },

    reSendCodigo() {
      this.tieneError = false;
      this.codigoResended = true;

      this.re_send_cod_cambio_mail();

      this.subTitle = this.t("app.comunes.autenticacion");
      this.buttons = ["Ok"];
      this.presentAlert();
    },
    async presentAlert() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader: this.subTitle,
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },
    async presentAlert_2(data) {
      let alert = await alertController.create({
        header: data.title,
        subHeader: data.msg,
        buttons: ['Ok'],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    respCodigoValidaMail() {
      this.tieneError = false;
      if (this.respCodigoValidaMail.status) {
        this.setshowSpinner(false);
        if (this.respCodigoValidaMail.resp) {
          this.remitenteUpdate();

          this.tieneError = false;
          this.borrarDatos = !this.borrarDatos;
          // if (this.procesoGeneral == "misDatos") {
          //   this.$router.push({ name: "misDatos", replace: true });
          // } else if (this.procesoGeneral == "logIn") {
          //   this.$router.push({ name: "home", replace: true });
          // }
        } else {
          this.tieneError = true;
        }
        this.procesando_codigo = false;
      }
    },
    async respRemitenteModif() {
      if (this.respRemitenteModif.status && this.enVista) {
        if (this.respRemitenteModif.resp && this.enVista) {
          if (this.procesoGeneral == "logIn") {
            this.$router.push({ name: "home", replace: true });
          } else {
            this.$router.push({ name: "misDatos", replace: true });
          }
        } else if (this.enVista) {
          console.log(
            "Error al registrar cambios: ",
            this.respRemitenteModif.msg
          );
          this.presentAlert_2({
            title: "Error",
            msg: this.t(`configuracion.errores_srv.Cliente.${this.respRemitenteModif.msg}`)
          });
        }
      }
    },
  },
  mounted() {
    // console.log(this.remitente);
  },
  ionViewDidEnter() {
    this.enVista = true;
  },
  ionViewWillLeave() {
    this.codigoResended = false;
    this.borrarDatos = !this.borrarDatos;
    this.dropRemitenteTemp();
    this.enVista = false;
  },
};
</script>

<style>
.regEditAutentiTitle {
  color: var(--customA-801B1C);
  margin: 3rem auto 0 auto;
  text-align: center;
}

.regEditAutentiText {
  /* font-size: 0.6875rem;
  font-weight: 500;
  line-height: 18px; */
  color: var(--customG-787878);
  margin-top: 2.3rem;
  text-align: justify;
  text-justify: inter-word;
  padding: 0.2rem;
}

.regEditAutentiTextBold {
  font-weight: 500;
  color: var(--customH-4C4C4C);
}

.regEditAutentiCodigo {
  margin-top: 4rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  text-align: center;
}

.regEditAutentiCodigoInput {
  margin-top: 1rem;
  font-size: 0.823rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  align-content: center;
  /* border:  1px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  align-items: center;
}

.regEditAutentiCodigoInput > div {
  display: flex;
  width: fit-content;
  /* border:  1px solid green; */
}

.regEditAutentiCodigoInput > div > div {
  width: 2rem;
  border-bottom: 1.4px solid var(--customG-787878);
  margin: 0 0.2rem 0 0.2rem;
  display: flex;
  justify-content: center;
  /* border: 1px solid blue; */
}

.regEditAutentiCodigoInput > div > div > input {
  font-size: 2rem;
  font-weight: 700;
  line-height: 28.13px;
  color: var(--customC-614AD3);
  width: 2rem;
  /* border: 1px solid green; */
  padding-left: 0.3rem;
}

.regEditAutentiResend {
  font-family: "Roboto";
  font-style: italic;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--customG-787878);
}

/* .autenticaProblems {
  position: absolute;
  bottom: 2rem;
  width: 70%;
}
.autenticaProblemsText {
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 12.89px;
  color: var(--customH-4C4C4C);
  display: flex;
  justify-content: center;
}
.autenticaProblemsBold {
  font-weight: 700;
  color: var(--customC-614AD3);
} */

.customBtnClassAutentica {
  font-size: 0.5rem;
  font-weight: 500;
  line-height: 9.38px;
  color: var(--customH-4C4C4C) !important;
}

.alert-sub-title {
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 18.38px;
  color: var(--customC-614AD3) !important;
  /* border: 1px solid green; */
}

.regEditAutentiLoginResendedCod {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  margin-top: 2.5rem;
  color: var(--customC-614AD3);
}

.regEditAutentiEtapas {
  margin-top: 5rem;
}
</style>